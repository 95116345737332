import Footer from "../components/Footer";
import Header2 from "../components/Header2";
import Header2Mobile from "../components/Header2Mobile";

export default function WynkTermsAndConditions () {
  return (
  <main>
    <section 
    className="main-section home-hero text-white" 
    >
      <Header2/>
      <Header2Mobile/>
      <section className="section-inner-padding flex items-center gap-12">
        <div className="w-full">
          <h2 className="text-center font-bold text-3xl sm:text-4xl">TERMS AND CONDITIONS</h2>
        </div>
      </section>
    </section>
    <section className="main-section text-[#888888]">
      <h3 className="font-semibold mb-6">TERMS AND CONDITIONS</h3>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Definitions</h3>
        <p>In this document, “User Agreement”, “Agreement”, “Terms and Conditions of Service” are synonymous and used interchangeably. In this Agreement, “you” or “your” or “User” refers to any person or entity using the service. Unless otherwise stated, “Wynk,” “we” or “our” or “us” will refer collectively to Wynk Limited and its subsidiaries, affiliates, directors, officers, employees, agents, and partners. You and Wynk are jointly referred to as ‘the Parties' in this document, “Wynk”, “our Services”, “the Services”, “the System”, “Wynk app”, “WynksupApp” “WynkVault”, “WynkSwitch”, “WynkSocial”, “WynkMove”, WynkMusic”, “the platform” and any other Wynk related product are synonymous and used interchangeably unless otherwise specified. “Wynk” means the range of products and services with the “Powered by Wynk” notice offered by Wynk and its partners as an online platform for the Management of:</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">WynkPass Prepaid</h3>
        <p>Wynk's Prepaid Pass gives you access to the Wynk platform so that you can begin earning immediately. You can receive trip requests, monitor your earrings in real time and cash out as well as conduct all of the other transactions that Wynk has to offer. We do not charge commission so all of your receipts are real money in your hands - net of required taxes, duties or levies. The pass amount and validity are listed on the type of purchase you buy. In addition, Wynk's General Terms and Conditions Apply Wynk's Prepaid Pass gives you access to the Wynk platform so that you can begin earning immediately. You can receive trip requests, monitor your earrings in real time and cash out as well as conduct all of the other transactions that Wynk has to offer. We do not charge commission so all of your receipts are real money in your hands - net of required taxes, duties or levies. The pass amount and validity are listed on the type of purchase you buy. In addition, Wynk's General Terms and Conditions Apply</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">WynkPass Postpaid</h3>
        <p>Wynk's Postpaid Pass gives you access to the Wynk platform so that you can begin earning immediately. You can receive trip requests, monitor your earrings in real time and cash out as well as conduct all of the other transactions that Wynk has to offer. A calculated percentage of your earnings will be retained until the price of the pass is paid for in full. After that, we do not charge commission so all of your receipts are real money in your hands - net of any required taxes, duties or levies. The pass amount and validity are listed on the type of purchase you buy. In addition, Wynk's General Terms and Conditions Apply</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Business Users</h3>
        <p>Payment of Salaries: Wynk through its partner Wynk enables direct payment of salaries from any bank account into beneficiaries' accounts in any bank or other designated financial schemes. Payment of Taxes: Wynk through its partner, Wynk enables remittance of all taxes including PAYE, VAT and WHT, to the relevant Tax authorities. Payment of Pension: Wynk through its partner Wynk enables remittance of pension contributions and delivery of accompanying schedules to Pension Fund Administrators and Pension Fund Custodians. Payroll, Pension Processing and Biometrics: Wynk through its partner Wynk enables customers to process their payroll and pensions and carry out biometrics enrollment and verification of their employees and pensioners. Schedule Delivery: Wynk through its partner Wynk enables delivery of matching schedules in recipients' specified formats. These schedules are sent to appropriate recipients simultaneously as payments are made.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Individuals and Other Users</h3>
        <p>Payment of Vendors and Third Parties: Wynk through its partner Wynk enables customers to make electronic payments from any bank account into the accounts of vendors, suppliers and other third parties in any bank or other designated financial schemes. Account Balance and Transaction Monitoring: Wynk through its partner Wynk enables customers at their convenience, to view their account balances across banks, and it provides clear and definitive status of transactions at all times. Payment Collection from Service Users: Wynk through its partner Wynk enables automated collection of fees and other charges from service beneficiaries via multiple channels. Value Added Services: Wynk through its partner Wynk also provides value-added services such as loan disbursement, transaction referencing, third-party application integration, etc. and also Marketplace services such as ticketing, airtime and utility vending, International transfers, etc.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Individuals and Other Users</h3>
        <p>Payment of Vendors and Third Parties: Wynk through its partner Wynk enables customers to make electronic payments from any bank account into the accounts of vendors, suppliers and other third parties in any bank or other designated financial schemes. Account Balance and Transaction Monitoring: Wynk through its partner Wynk enables customers at their convenience, to view their account balances across banks, and it provides clear and definitive status of transactions at all times. Payment Collection from Service Users: Wynk through its partner Wynk enables automated collection of fees and other charges from service beneficiaries via multiple channels. Value Added Services: Wynk through its partner Wynk also provides value-added services such as loan disbursement, transaction referencing, third-party application integration, etc. and also Marketplace services such as ticketing, airtime and utility vending, International transfers, etc.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Contractual Relationship:</h3>
        <p>These terms of use (“terms”) govern the access or use by you, an individual of applications, websites, content, products, and services (the “services”) made available by Wynk Group Limited (herein referred to as “Wynk”), a private limited liability company headquartered in Canada, having its office registered address at 65 Crestview drive, Komoka, Ontario, Canada Please read these terms carefully before accessing or using the services: Your access and use of the services constitutes your agreement to be bound by these terms, which establishes a contractual relationship between you and Wynk. If you do not agree to these terms, you may not access or use the services. These terms expressly supersede prior agreements or arrangements with you. Wynk may immediately terminate these terms or any services with respect to you, or generally cease offering or deny access to the services or any portion thereof, at any time for any reason.</p>
        <p>Supplemental terms may apply to certain services, such as policies for a particular event, activity or promotion, and such supplemental terms will be disclosed to you in connection with the applicable services. Supplemental terms are in addition to, and shall be deemed a part of, the terms for the purposes of the applicable services. Supplemental terms shall prevail over these terms in the event of a conflict with respect to the applicable services. Wynk may amend the terms related to the services from time to time. Amendments will be effective upon wynk's posting of such updated terms at this location or the amended policies or supplemental terms on the applicable service. Your continued access or use of the services after such posting constitutes your consent to be bound by the terms, as amended. Our collection and use of personal information in connection with the services is as provided in Wynk's privacy policy Wynk may provide to a claim's processor or an insurer any necessary information (including your contact information) if there is a complaint, dispute or conflict, which may include an accident, involving you and a third-party provider (including a transportation network company driver) and such information or data is necessary to resolve the complaint, dispute or conflict.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">The Services:</h3>
        <p>The services constitute a technology platform that enables users of Wynk mobile applications or websites provided as part of the services (each, an application) to arrange and schedule transportation and/or logistics services with independent third-party providers of such services, including independent third-party transportation providers and independent third-party logistics providers under agreement with Wynk or certain of Wynk affiliates (third party providers). Unless otherwise agreed by Wynk in a separate written agreement with you, the services are made available solely for your personal, noncommercial use. You acknowledge that Wynk does not provide transportation or logistics services or function as a transportation carrier and that all such transportation or logistics services are provided by independent third-party contractors who are not employed by Wynk or any of its affiliates.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Payment Services:</h3>
        <p>Wynk facilitates your transfer to and receipt of funds from third parties. Based on your instructions, you acknowledge that:</p>
        <p>Payment instructions on the platform are driven strictly by account numbers and not by the beneficiary names supplied.</p>
        <p>Payments will be applied by Wynk Partner Banks into the beneficiary account numbers supplied by you at the time of upload of payment instructions.</p>
        <p>Any payment instruction, including but not limited to standing instruction related debits, processed through Wynk that failed processing for any reason whatsoever will be automatically retried towards ensuring its successful processing.</p>
        <p>You are fully responsible for the completeness, correctness and validity of data supplied on the platform, including but not limited to:</p>
        <p>Beneficiary account number</p>
        <p>Beneficiary account name</p>
        <p>Amount</p>
        <p>Beneficiary phone number</p>
        <p>Beneficiary e-mail address</p>
        <p>Neither WYNK nor any of the Partner Banks can be held liable for any incorrect beneficiary name, account number, amount, phone number, e-mail address or other data that a registered user uploads on the platform.</p>
        <p>Wynk is not a bank.</p>
        <p>We are not acting as a trustee, fiduciary or escrow with respect to your funds. When you send a payment instruction, until that payment is accepted by the recipient, you remain the owner of those funds, but you will not be able to withdraw those funds or send the funds to any other recipient unless the initial transaction is cancelled. We act as service providers by creating, hosting, maintaining, and providing our Service to you through the Internet. We do not have any control over the products or services that are paid for through our Service. We cannot ensure that a buyer or a seller you are dealing with will actually complete the transaction and we shall not bear any liability for any incomplete transaction between you and the other party with whom you may be transacting. When you use Wynk to manage the payroll of your organization, Wynk shall use best efforts to process the information provided by you to ensure that the computed figures are accurate and represent your expectations. It however remains your responsibility to confirm your satisfaction with computed figures before you approve same for payment. When you request Wynk to compute your taxes based on the Nigerian tax laws, Wynk shall use best efforts to process information provided by you to ensure that the computed figures are accurate and represent the expectations of the tax authorities. It however remains your responsibility to confirm your satisfaction with computed figures before you approve same for payment.</p>
        <p>When you use Wynk for collection of funds by way of an automated direct debit from the accounts of third parties, such parties will be entitled to a prompt refund of such payments simply by submitting appropriate claims to us in accordance with the relevant direct debit guidelines from the Central Bank of Nigeria or other regulatory requirements. When you use Wynk or its partners as a registered Biller/Collector/Merchant for collection of funds paid by service beneficiaries into your designated collection account(s) through any of the Wynk payment channels such as any bank branch, online banking sites, debit/credit cards, digital wallets, POS or mPOS terminals, direct debit, standing order, cash collection points etc., Wynk shall use best efforts to process information provided by the payer in accordance with what has been entered into the system such that funds collected are accurate and represent your expectations. It however remains your responsibility to confirm your satisfaction with collected funds for which the payer is enjoying your products or services.</p>
        <p>Wynk would normally settle collected amounts into merchant accounts on the same day, except for funds collection via debit/credit cards, digital wallets, POS and mPOS terminals and other card related transactions, settlement for which is normally done to merchants accounts on a T+1 basis (the day after the transaction), in line with industry standards. International payments will be subject to international best practices. When you use Wynk or its partners as a registered Biller for collection of funds paid by service beneficiaries into your designated collection account(s) through any of the Wynk payment channels, You hereby grant us the unconditional right (without any liability whatsoever) to publish your name as a Biller on any other platform owned by WYNK and/or any other aggregator's platform with whom we have a business relationship, for the purpose of facilitating the collection of payments due to you, and expanding your access to payers.</p>
        <p>In addition to being able to access your Wynk profile through Wynk web and the Wynk mobile application, by becoming a registered user on the platform, you may be able to make payments directly from the portal/platform of a Biller/Merchant registered on Wynk. In such an instance, you shall be required to authenticate your status as a registered Wynk user, select any of your bank accounts that are profiled on the platform from which the payment is to be made and authorize your payment accordingly” As a Biller, you shall inform us of any change in control or ownership of your business. You will inform us of any alliance, joint venture, partnership, or any other business combination arrangement You may go into with any foreign entity.</p>
        <p>You shall not accept payment for or on behalf of any third Party through Wynk. You shall fully comply with all applicable Payment Schemes rules and regulations, Card usage and acceptance requirements and merchant monitoring standards. We reserve the right to terminate this Agreement in the event any of Your contracts with a payment scheme provider or Bank partner is terminated for any reason whatsoever. We shall not oblige Your request to switch off any authenticated procedures insofar as it pertains to the processing of a card payment transaction.</p>
        <p>You must not submit, and we shall not accept, any transaction that you know is, or should have known was, illegal or fraudulent.</p>
        <p>You shall at all times, prevent fraud through your action or inaction on Wynk, and shall provide us with reasonable assistance upon request, for the prevention and detection of fraud or other criminal activity in respect of transaction.</p>
        <p>You shall be responsible for the conduct of your employees, agents, and representatives as it pertains to the use of Wynk as contemplated in this Agreement, and you shall indemnify us for any loss we may suffer as a result of any action or inaction of your employees, agents and/or representatives.</p>
        <p>We shall not accept transactions made using a payment card for any Debt repayment (unless authorized by us in writing).</p>
        <p>Identity Authentication We use many techniques to identify users when they register on the platform. Verification of Users is only an indication of increased likelihood that a user's identity is correct. You authorize us to, directly or through third parties, make any inquiries we consider necessary to validate your registration. This may include verifying the information you provide against third party databases. In addition, we reserve the right to employ other means of verification of authenticity for transactions we deem suspicious or for accounts conducting high value or high-volume transactions to ensure integrity of the transactions and we may thus delay execution of such instructions.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">No Warranty</h3>
        <p>We provide our services "as is" and without any warranty or condition, express, implied, or statutory. We, specifically disclaim any implied warranties of title, merchantability, fitness for a particular purpose and non-infringement to the full extent permissible by the law. We shall provide the Services with due care and skill, in consideration for your payment of the applicable fee or charge. Please be informed that our Services may suffer from delays, errors, or other unintended outcomes. We shall make reasonable efforts to ensure that requests for electronic debits and credits and other transactional operations are processed in a timely manner, but we make no representations or warranties regarding the amount of time needed to complete processing because our Service is also dependent upon other factors outside of our control, one of which is the operational efficiency of all stakeholders.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Limitation of Liability:</h3>
        <p>We shall only be liable for your direct loss or damage, proven to be a foreseeable result of our breach of this agreement. We will also be liable for any obligation that cannot be limited or excluded as a matter of law.</p>
        <p>We will not be liable for any loss or damage arising as a result of unauthorized access to the service if (a) You intentionally or negligently failed to take reasonable precautions to protect your security, access codes, login details or any device used to access the service, (b) You failed to promptly notify us that the service was being accessed in an unauthorized way after becoming aware of it, or (c) You acted fraudulently.</p>
        <p>In no event shall WYNK be liable for loss of income, profits, business, opportunity, contracts or any indirect, special, incidental, or consequential damages arising out of or in connection with our platform, our service, or this Agreement.</p>
        <p>Our liability to you or any third party in any circumstance of proven liability by us, shall not exceed the fees paid to us in respect of the specific transaction that gave rise to the claim or liability.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Indemnification</h3>
        <p>You agree that if you are in breach of this Agreement or the documents it incorporates by reference, or if you violate any law or the rights of a third party in connection with your use of the service, we shall not be responsible for any damage, loss, liability or third party claim you incur wholly or partly by your breach or violation. You agree to indemnify and hold Wynk, harmless from any claim or demand (including attorneys' fees) made by you or any third party arising wholly or partly from your breach of this Agreement or the documents it incorporates by reference, or your violation of any law or the rights of a third party relating to your use of the Service.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Authorized Signatories:</h3>
        <p>You undertake that:</p>
        <p>Authorized signatories shall be setup on Wynk to:</p>
        <p>Approve remittance instructions and other relevant transactions in accordance with your internal approval rules. You understand that these approval rules will be applied to all remittance instructions and other relevant transactions processed on Wynk and therefore will not be subjected to telephone, e-mail or any other manual confirmation by banks before transactions are completed.</p>
        <p>Approve on your behalf, subscription for additional services that may be offered by us on the Platform.</p>
        <p>If any Authorized signatory or any of your other registered users on the Platform cease to be your employee, you must immediately terminate his/her access to Wynk and any other service which the Authorized signatory or registered user can access on your behalf. You will be responsible for any and all actions taken by your Authorized signatory or any of your other registered users on the Platform. You must notify us immediately you notice or suspect any unauthorized use of your profile on the Platform.</p>
        <p>Authorized signatory or any of your other registered users on the Platform. You must notify us immediately you notice or suspect any unauthorized use of your profile on the Platform.</p>
        <p>You will keep confidential all Wynk security related information such as passwords, Access Codes and Personal Identification Numbers (PIN). You understand that Wynk, banks, their affiliates and service providers will never request you to divulge any of these information by phone, mail or any other means. You are obliged to report any representation to the contrary to Wynk and your bank promptly.</p>
        <p>Payment instructions made to your banks by cheques, or any channel other than Wynk, will continue to be subjected to all your current mandate instructions and confirmation rules. When you make a payment through Wynk, you are requesting an electronic transfer from your account. Upon such request, Wynk will transmit your instructions to your bank to transfer from your account the amount you specify. You agree that such requests constitute your authorization for such transfers.</p>
        <p>Except when caused by Wynk's or the bank's misconduct or negligence, you will protect Wynk, the banks, their affiliates and service providers from any/and all claims, liability, damages, expenses and costs caused by or arising from your use of the service.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">WYNK LOAN SERVICE:</h3>
        <p>Users of Wynk Loan Service grant permission to Wynk to access their bank account details for the purpose of loan disbursement and repayment. Bank account details will be securely stored and used solely for the purpose of facilitating loan transactions on the Wynk Super App. Wynk will retrieve loan amounts directly from the user's designated bank account based on the agreed repayment schedule and indicated payment method. </p>
        <p>By applying for and using Wynk Loans, users authorise Wynk to initiate transactions from their bank account for loan disbursement and repayment purposes. Users understand that failure to maintain sufficient funds in their bank account for loan repayment may result in additional fees, penalties, or collection actions as outlined in this section. To facilitate easy repayments, Users can make loan repayments through various approved methods, including but not limited to bank transfers, direct deductions from imputed bank account details, and indicated Wynk vault for repayment. As such, User agrees that any such account details inputed for the purpose of repayment must be an active account suitable for purposes relevant to Wynk loan repayment. </p>
        <p>The user also agrees to repay the loan amount along with any accrued interest according to the terms as shall be determined by Wynk. Failure to repay the loan on time may result in additional fees, penalties, and legal action as deemed necessary. As such, persistent failure to make payments may result in the loan being classified as delinquent, leading to further actions by the company to recover the outstanding amount. This may also include a permanent ban on defaulting users from Wynk loan service.</p>
        <p>Pursuant to the above, Wynk  reserves the right to employ various methods for loan collection, including contacting the borrower via phone, email, or mail to remind them of overdue payments.  Where deemed necessary, third-party collection agencies may be engaged to recover outstanding debts.</p>
        <p>Wynk shall also retain to the exclusion of all others, to accelerate the loan and demand immediate repayment of the outstanding balance if the borrower breaches any terms of the loan agreement. As part of our commitment to regulatory compliance and risk management, Wynk shall conduct a thorough KYC process for all Users willing to obtain loans. This process will include verifying the User's identity, address, income, employment status, and other relevant information through documents and databases. The User  agrees to provide accurate and up-to-date information during the KYC process and acknowledges that providing false or misleading information may result in rejection of the loan application. With strict regards to data privacy and security, Wynk maintains strict confidentiality regarding the borrower's personal and financial information collected during the KYC process. All data is securely stored and processed.</p>
        <p>Additionally, Wynk reserves the right to modify or update these terms and conditions at any time without prior notice. However, Users may be notified of any changes to the terms and conditions via the Wynk platform or other designated communication channels.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Fees:</h3>
        <p>The processing fees payable for our services are as displayed on your Wynk profile screen during transaction processing or as stated below. The fees are computed per record and charged alongside each batch of transactions that you process.</p>
        <p>The fees payable for our services are:</p>
        <p>Bills Payments (Regular): 1.5% of transaction value subject to a maximum of ₦2,000 per transaction. Fees for payments in favor of educational institutions is N300 per transaction. Payroll Setup: ₦500 per employee (payable only at the first time of enrolling an employee) Payroll Processing (Employee or Pensioner): ₦250 per transaction.</p>
        <p>Payment to Vendors, Payroll/Salary Beneficiaries and other 3rd parties with value-add services:</p>
        <p>₦100 per million, subject to a maximum of ₦5,000 per transaction.</p>
        <p>Funds Transfer (Individual):</p>
        <p>Below ₦5,000 – ₦50;</p>
        <p>N5,001 - ₦50,000 – ₦50;</p>
        <p>Above ₦50,000 – ₦50</p>
        <p>Funds Transfer (Others):</p>
        <p>1 - 250,000 records - ₦100/ transaction</p>
        <p>250,001 - 500,000 records - ₦100/ transaction</p>
        <p>500,001 - 750,000 records - ₦100/ transaction</p>
        <p>750,001 - 1,000,000 records - ₦100/ transaction</p>
        <p>Over 1,000,000 records - ₦100/ transaction</p>
        <p>Direct Debit/ Standing Order: 1% Maximum of ₦1,000 per transaction.</p>
        <p>Schedule Delivery: ₦2,500 per Schedule per Recipient.</p>
        <p>Self Service: ₦50 per employee per month.</p>
        <p>Performance appraisal: ₦50 per employee per month.</p>
        <p>Claims processing: ₦50 per employee per month.</p>
        <p>Leave: ₦50 per employee per month.</p>
        <p>Loan Collections: 1% of loan amount</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Convenience Fees:</h3>
        <p>Where applicable and subject to our discretion, we may charge a convenience fee in addition to the applicable fee listed above. In such an instance, the Payer shall be notified of the amount payable at the point of consummating the transaction, Special Prices not included here: The prices stated in this Agreement are for the standard service offerings on the platform. Special prices will be agreed for projects with features extending beyond our standard services.</p>
        <p>The above fees are computed and charged alongside each batch of transactions that you process. Any disparity between the fees stated herein and the fees stated on your Wynk Profile Screen will be resolved in favor of the fees stated on your Wynk Profile Screen during transaction processing.</p>
        <p>Fees for Extended Support Services:</p>
        <p>Consultancy, audit and investigative support services are charged at ₦100,000 per consultant per day, regardless of time spent, up to a maximum of 8 hours per day. This fee covers our consultant's time and expertise spent on the assignment. It excludes reimbursable expenses for logistics, travel, hotel accommodation and other incidentals outside Lagos; report production, stationeries, and photocopying which are charged separately.</p>
        <p>User Administration requests are charged at ₦10,000 per amendment request. Customization and/or integration charges are specified per project and will be determined at the time of scoping the work, as the scope of work involved may vary. The fees for our Training services are published alongside our Training schedules on our website. Payment for all our extended services shall be upfront and paid in full.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Fees Exclusive of Taxes:</h3>
        <p>All fees and charges quoted in this Agreement exclude any applicable taxes, which will be chargeable at the prevailing rate. You will be responsible for the payment of any taxes imposed by any governmental taxing authority on the amounts you are liable to pay to us under this Agreement, including, but not limited to, withholding taxes of whatever nature. If any deductions or withholdings are required by law to be made from the fees payable to us, you agree to promptly pay such Withholding Taxes and obtain and deliver to us proof of payment of such Withholding Taxes together with official evidence thereof issued by the governmental authority concerned, sufficient to enable us to support a claim for a tax credit in respect of any sum so withheld. If we are unable to obtain such tax credit due to your failure to comply with the above provision, then you agree to pay to us a sum equal to the amount of the tax credit we are not able to claim as a result of your failure.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Change of Fees:</h3>
        <p>Wynk reserves the right to change the processing fees as well as the fees for extended support services. Notices of such changes shall be communicated in line with the provisions of this Agreement. Chargebacks, Refunds and Reversals As a registered biller on Wynk, we may apply chargebacks against your collection accounts for:</p>
        <p>Disputed payments reported by a Payer to which satisfactory evidence of goods and service delivery could not be provided by you, transactions that we suspect to be unlawful.</p>
        <p>Transactions that are prohibited under this Agreement.</p>
        <p>Transactions that do not comply with card network/scheme rules or the terms of this Agreement or any reversals for any reason by the card network/scheme, our processor, or the participating banks.</p>
        <p>Where a chargeback occurs, you shall become immediately liable for all claims, fines, liabilities and expenses we incur arising out of that chargeback. As a beneficiary of any form of payment on Wynk, if it is established that your account was erroneously credited with a value (“Wrongful Payment”), appropriate reversals will be triggered against such account into which the Wrongful Payment was credited, till the entire amount is fully reversed.</p>
        <p>Wrongful Payments include but are not limited to (a) unfunded payments, (b) multiple payments for the same set of transactions and (c) fraudulently processed payments.</p>
        <p>We do not guarantee or assume any liability for transactions authorized and completed that are later reversed or charged back. You are solely responsible for all reversed or charged back transactions, regardless of the reason, basis, or duration of the reversal or chargeback. You hereby grant us the unconditional right to deduct any amount, fee, fine or penalty due to be paid by you as a result of any reversals, chargebacks, or violation of card scheme rules. You agree that we shall debit your collection account, or any account linked to your BVN to enable us to recover any fee, fine or penalty, and/or set-off the applicable amounts against future settlements due to you.</p>
        <p>We shall not be involved in any repayment claim from you, after a chargeback claim has been successfully settled in favor of a customer.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Use Policy:</h3>
        <p>Acceptable Use Policy</p>
        <p>The following activities constitute a violation of the Acceptable use of Wynk Using the Wynk service to make or receive payments for any illegal, fraudulent, immoral, or otherwise socially reprehensible purposes including but not limited to materials that infringe the intellectual property rights of third parties. Using the Wynk service to make or receive payments for any narcotics, other controlled substances, steroids or illicit drugs.</p>
        <p>Using Wynk to make or accept payments for goods or services where payment is not yet due and for which no invoice exists which can be presented to WYNK upon request. Using Wynk to make or accept payment or conduct activities in a manner that WYNK or any of its partners reasonably believe to be an abuse or violation of existing rules by regulators, Card scheme providers or the CBN.</p>
        <p>Using Wynk to make or accept payments for the following high-risk activities without WYNK's prior written approval.</p>
        <p>Sell securities, business opportunities, franchise, multi-level marketing, or for the pre-order of goods. Payment for any sexually oriented or obscene materials or services. Buy or sell cryptographic currencies.</p>
        <p>Inbound and outbound international money transfer services. payments for wagers, gambling debts or gambling winnings, regardless of the location or type of gambling activity. Offer, or conduct any form of direct marketing activities (such as, but not limited to inbound and/or outbound telemarketing activities) and travel related services. payments for any prescription and proprietary drugs and non-prescription (over the counter) medicines. payments for the wholesale distribution of prescription drugs, proprietary drugs, vitamins, druggists’ sundries and toiletries, antiseptics, bandages, pharmaceuticals, and biological or related products.</p>
        <p>Receive payments in exchange for personal property left with you, as a merchant, as security (in other words, any service associated with a pawn shop); Receive payments for high-value jewelry, precious stones, gems, gold, platinum, silver or minerals; and/or Receive payment for tobacco or liquor.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Restricted Activities:</h3>
        <p>You agree that your information and your activities (including your payments and receipt of payments) through our Service shall not:</p>
        <p>Be false, inaccurate, or misleading.</p>
        <p>Be fraudulent or involve the sale of counterfeit or stolen items.</p>
        <p>Be related in any way to terrorism and/or criminal activities, including but not limited to payment or the acceptance of payments for unauthorized firearms or weapons.</p>
        <p>Infringe on any third party's copyright, patent, trademark, trade secret or other property rights or rights of publicity or privacy. Violate any law, statute, ordinance, contract or regulation (including, but not limited to, those governing financial services, consumer protection, unfair competition, antidiscrimination, or false advertising); Be defamatory, libelous, unlawfully threatening or unlawfully harassing. Be obscene or contain child pornography. Contain any viruses, Trojan horses, worms, time bombs, cancel bots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, surreptitiously intercept, or expropriate any system, data or other personal information; or Create liability for us or cause us to lose (in whole or in part) the services of our ISP's or other suppliers.</p>
        <p>If you use or attempt to use the Service for purposes other than sending and receiving payments, managing your account and the services listed in clause above, including but not limited to tampering with, hacking, modifying or otherwise corrupting the security or functionality of the Service, your account will be terminated and you will be subject to claims for damages and other lawful penalties, including criminal prosecution where applicable.</p>
        <p>Access and Interference</p>
        <p>You agree that you will not use any robot, spider, other automatic device, or manual process to monitor or copy our web pages or the content contained herein without our prior express written permission. You agree that you will not use any device, software or routine to attempt to interfere with the proper working of the Wynk site or any activities conducted on our site. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on our infrastructure. Most of the information on our site is proprietary or is licensed to us. You agree that you will not copy, reproduce, alter, modify, create derivative works, publicly display or frame any content from our website without our prior express written permission.</p>
        <p>Our Remedies and Right to terminate or restrict your activities: Without limiting other remedies available to us, we may verify inaccurate or incorrect information you provide to us, contact you by means other than by electronic means, immediately warn our community of your actions, limit access to an account and any or all of the account's functions (including but not limited to the ability to send money or make payments), limit activities, indefinitely suspend or close your account, terminate this Agreement and refuse to provide our Services to you if:</p>
        <p>You breach this Agreement or the documents it incorporates by reference. We are unable to verify or authenticate any information you provide to us. We believe that your account or activities pose a significant fraud risk to us. We believe that your actions may cause financial loss or legal liability for you, our users or us. We believe that any applicable rule stipulated by the Payment schemes may affect your use of the platform. Your use of Wynk is deemed by us to constitute abuse of the electronic payment system or electronic payment rules, including (without limitation), using the Wynk system to test Wynk card behaviors.</p>
        <p>For the avoidance of doubt, if a fraudulent activity is associated with the operation of your Wynk profile, you agree that we have the right to apply restrictions to the profile and report to appropriate law enforcement agencies. Suspension of Activated Mandates. Without recourse to you, we may suspend any activated mandate, debit or credit instruction initiated by you if; We consider such suspension would be in the interest of any of the stakeholders on the platform; or There are reasonable grounds to suspect a compromise of security or any unauthorized activity on your registered bank account or profile on the Platform. Further to clause above, you understand that such a suspension may impact your ability to initiate any transaction, activate another debit against a suspended mandate or complete any duly authorized transaction for the duration of such suspension. In such an instance, we shall not be liable to you for any reason whatsoever.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Privacy and Security:</h3>
        <p>We view the protection of users' privacy as a very important principle. We understand clearly that you and your Information are one of our most important assets. We store and process your Information on computers that are protected by physical as well as technological security devices. We do not give your personal information to third parties for marketing purposes without your consent. You may object to your information being used in this way and thereby opt out from using our Services. Please ensure that you read our privacy policy available on www.wynk.ng By consenting to this Agreement, you also consent to our privacy policy.</p>
        <p>You hereby grant us the right to conduct any investigation in any manner we deem fit, on your background and operations, including but not limited to credit background checks, banking relationships and financial history. In addition, you hereby give us your consent to disclose the details of any investigation conducted pursuant to this clause, to Payment scheme, processors, Bank partners and/or regulators, without recourse to you. Warranty and Service Disruption We warrant that the service will in all material respects, deliver on the agreed terms herein. Should the service be disrupted to such an extent that there is likely to be an adverse effect to the service provided, we will endeavor to notify you of such within a reasonable time. In the event of any service delay or failure, we shall take necessary steps to ensure speedy service restoration and reduce to the barest minimum the extent of such service failures.</p>
        <p>However we shall not be liable to you for any loss or damage. As a Biller, You warrant that you are not a payment service provider and you do not intend to provide any form of aggregation services. You warrant that you have never had an agreement with a Payment scheme which was terminated upon request and/or demand by the Payment scheme or any regulatory authority.</p>
        <p>You shall, upon written request by us, provide us with any information we request of you, including your company registration documents, and you hereby give us your consent to disclose any information obtained pursuant to this clause, to any government regulator or payment scheme provider.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">WYNK KYC:</h3>
        <p>As part of our KYC process, Wynk Users are required to provide their Bank Verification Number (BVN) alongside a host of User's personal details. The BVN will be collected and securely stored in accordance with relevant data protection laws and regulations. The BVN will only be used for the purpose of verifying the identity of Wynk Users and ensuring compliance with regulatory requirements.</p>
        <p>By using the Wynk platform, users consent to the collection and processing of their BVN for KYC purposes. Users understand that failure to provide accurate BVN information may result in limitations or restrictions on their access to certain features or services pertaining to the vault Section within the Wynk platform.</p>
        <p>Wynk undertakes liability as may be determined by extant data protection laws and regulations in the instance of breach.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Extended Non-use:</h3>
        <p>Your non-use of our platform for an extended period does not terminate this contract. Any monies due and payable by you to us before, during or after the period of your passivity shall remain payable and become due immediately upon your reactivation. Termination or non-use shall not relieve the continuing obligations under this Agreement, including but not limited to the requirements in Clauses related to Indemnification, Access and Interference, and Trademarks and other intellectual Proprietary Rights of this Agreement.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">License:</h3>
        <p>Subject to your compliance with these terms, Wynk grants you a limited, non-exclusive, non-sub licensable, revocable, non-transferrable license to: (i) access and use the applications on your personal device solely in connection with your use of the services; and (ii) access and use any content, information and related materials that may be made available through the services, in each case solely for your personal, noncommercial use. Any rights not expressly granted herein are reserved by Wynk and Wynk licensors.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Restrictions:</h3>
        <p>You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the services except as expressly permitted by Wynk (iii) decompile, reverse engineer or disassemble the services except as may be permitted by applicable law; (iv) link to, mirror or frame any portion of the services; (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the services or unduly burdening or hindering the operation and/or functionality of any aspect of the services; or (vi) attempt to gain unauthorized access to or impair any aspect of the services or its related systems or networks.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Provision of the services:</h3>
        <p>You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the services except as expressly permitted by Wynk (iii) decompile, reverse engineer or disassemble the services except as may be permitted by applicable law; (iv) link to, mirror or frame any portion of the services; (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the services or unduly burdening or hindering the operation and/or functionality of any aspect of the services; or (vi) attempt to gain unauthorized access to or impair any aspect of the services or its related systems or networks.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Third party services and content:</h3>
        <p>The services and all rights therein are and shall remain wynk's property or the property of wynk's licensors. Neither these terms nor your use of the services conveys or grant to you any rights: (i) in or related to the services except for the limited license granted above; or (ii) to use or reference in any manner wynk's company names, logos, product and service names, trademarks, or services marks or those of wynk's licensors.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Ownership:</h3>
        <p>You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the services; (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the services except as expressly permitted by Wynk (iii) decompile, reverse engineer or disassemble the services except as may be permitted by applicable law; (iv) link to, mirror or frame any portion of the services; (v) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the services or unduly burdening or hindering the operation and/or functionality of any aspect of the services; or (vi) attempt to gain unauthorized access to or impair any aspect of the services or its related systems or networks.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Your use of the services:</h3>
        <p>User accounts:</p>
        <p>In order to use most aspects of the services, you must register for and maintain an active personal user services account (“account”). You must be at least 18 years of age, or the age of legal majority in your jurisdiction (if different than 18), to obtain an account. Account registration requires you to submit to Wynk certain personal information, such as your name, address, mobile phone number and age, as well as at least one valid payment method (either a credit card or accepted payment partner). You agree to maintain accurate, complete, and up-to-date information in your account. Your failure to maintain accurate, complete, and up-to-date account information, including having an invalid or expired payment method on file, may result in your inability to access and use the services or wynk's termination of these terms with you. You are responsible for all activity that occurs under your account, and you agree to maintain the security and secrecy of your account username and password at all times. Unless otherwise permitted by Wynk in writing, you may only possess one account.</p>
      </article>
     <article className="mb-6">
        <h3 className="font-semibold mb-2">User requirements and conduct:</h3>
        <p>The service is not available for use by persons under the age of 18. You may not authorize third parties to use your account, and you may not allow persons under the age of 18 to receive transportation or logistics services from third party providers unless they are accompanied by you. You may not assign or otherwise transfer your account to any other person or entity. You agree to comply with all applicable laws when using the services, and you may only use the services for lawful purposes (e.g., no transport of unlawful or hazardous materials). You will not, in your use of the services, cause nuisance, annoyance, inconvenience, or property damage, whether to the third-party provider or any other party. In certain instances, you may be asked to provide proof of identity to access or use the services, and you agree that you may be denied access to or use of the services if you refuse to provide proof of identity.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Text messaging:</h3>
        <p>By creating an account, you agree that the services may send you text (SMS) messages as part of the normal business operation of your use of the services. You may opt-out of receiving text (SMS) messages from Wynk at any time by following the directions found in “settings”. You acknowledge that opting out of receiving text (SMS) messages may impact your use of the services.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Promotional codes:</h3>
        <p>By creating an account, you agree that the services may send you text (SMS) messages as part of the normal business operation of your use of the services. You may opt-out of receiving text (SMS) messages from Wynk at any time by following the directions found in “settings”. You acknowledge that opting out of receiving text (SMS) messages may impact your use of the services.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Promotional codes:</h3>
        <p>Wynk may, in its sole discretion, create promotional codes that may be redeemed for account credit, or other features or benefits related to the services and/or a third-party providers service, subject to any additional terms that Wynk establishes on a per promotional code basis (“promo codes”). You agree that promo codes: (i) must be used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated, sold or transferred in any manner, or made available to the general public (whether posted to a public form or otherwise), unless expressly permitted by Wynk; (iii) may be disabled by Wynk at any time for any reason without liability to Wynk; (iv) may only be used pursuant to the specific terms that Wynk establishes for such promo code; (v) are not valid for cash; and (vi) may expire prior to your use. Wynk reserves the right to withhold or deduct credits or other features or benefits obtained through the use of promo codes by you or any other user in the event that Wynk determines or believes that the use or redemption of the promo code was in error, fraudulent, illegal, or in violation of the applicable promo code terms or these terms.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">User provided content:</h3>
        <p>Wynk may, in wynk's sole discretion, permit you from time to time to submit, upload, publish or otherwise make available to Wynk through the services textual, audio, and/or visual content and information, including commentary and feedback related to the services, initiation of support requests, and submission of entries for competitions and promotions (“user content”). Any user content provided by you remains your property. However, by providing user content to Wynk, you grant Wynk a worldwide, perpetual, irrevocable, transferrable, royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works of, distribute, publicly display, publicly perform, and otherwise exploit in any manner such user content in all formats and distribution channels now known or hereafter devised (including in connection with the services and wynk's business and on third-party sites and services), without further notice to or consent from you, and without the requirement of payment to you or any other person or entity.</p>
        <p>You represent and warrant that: (i) you either are the sole and exclusive owner of all user content or you have all rights, licenses, consents and releases necessary to grant Wynk the license to the user content as set forth above; and (ii) neither the user content nor your submission, uploading, publishing or otherwise making available of such user content nor wynk's use of the user content as permitted herein will infringe, misappropriate or violate a third party’s intellectual property or proprietary rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation. You agree to not provide user content that is defamatory, libelous, hateful, violent, obscene, pornographic, unlawful, or otherwise offensive, as determined by Wynk in its sole discretion, whether or not such material may be protected by law. Wynk may, but shall not be obligated to, review, monitor, or remove user content, at wynk's sole discretion and at any time and for any reason, without notice to you.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Other provisions:</h3>
        <p>Claims of copyright infringement:</p>
        <p>Claims of copyright infringement should be sent to wynk's designated agent. Please visit wynk's web page at www.wynk.ng for the designated address and additional information.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Notice:</h3>
        <p>Wynk may give notice by means of a general notice on the services, electronic mail to your email address in your account, or by written communication sent to your address as set forth in your account. You may give notice to Wynk by written communication to wynk's address at innovators@wynk.ng.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">General:</h3>
        <p>You may not assign or transfer these terms in whole or in part without wynk's prior written approval. You give your approval to Wynk for it to assign or transfer these terms in whole or in part, including to: (i) a subsidiary or affiliate; (ii) an acquirer of wynk's equity, business, or assets; or (iii) a successor by merger. No joint venture, partnership, employment, or agency relationship exists between you, Wynk or any third-party provider as a result of the contract between you and Wynk or use of the services. If any provision of these terms is held to be illegal, invalid, or unenforceable, in whole or in part, under any law, such provision or part thereof shall to that extent be deemed not to form part of these terms but the legality, validity and enforceability of the other provisions in these terms shall not be affected. In that event, the parties shall replace the illegal, invalid, or unenforceable provision or part thereof with a provision or part thereof that is legal, valid and enforceable and that has, to the greatest extent possible, a similar effect as the illegal, invalid or unenforceable provision or part thereof, given the contents and purpose of these terms. These terms constitute the entire agreement and understanding of the parties with respect to its subject matter and replaces and supersedes all prior or contemporaneous agreements or undertakings regarding such subject matter. In these terms, the words “including” and “include” mean “including”, but not limited to.</p>
        <p>Dispute Resolution Negotiated Settlement or Mediation In the event of a dispute arising between you and WYNK, our goal is to provide you with a neutral and cost-effective means of resolving the dispute quickly. In the event of any dispute, the Parties shall seek to resolve any such dispute amicably between themselves or through a negotiated settlement and in the event of their inability to resolve the dispute as aforesaid, the parties shall explore a mediated settlement with both Parties appointing one(1) Mediator who shall act as a catalyst for resolution. Arbitration If at any time the Parties are unable to amicably resolve any dispute(s) through negotiated settlement or mediation, either party shall refer the matter to be finally settled by arbitration in accordance with the Arbitration & Conciliation Act, Cap A18, Laws of the Federation of Nigeria (LFN) 2004. The arbitration shall take place in Lagos, Nigeria and be conducted in English Language. . If the parties fail to agree on the Arbitrator, the Arbitrator shall be appointed by the President of the Chartered Institute of Arbitrators UK (Nigeria Branch). Each Party will bear its costs save for joint costs which will be borne jointly.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Entire Agreement</h3>
        <p>This agreement and any documents referred to herein constitute the entire agreement between the parties and supersede any and all prior agreements between the parties, whether oral or written, with respect to the subject matter thereof.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Applicable Law</h3>
        <p>This Agreement shall be governed by and interpreted according to the laws of the Federal Republic of Nigeria and shall be subject to the exclusive jurisdiction of Nigerian courts. Improvement and Changes These Wynk functions are subject to continuous technological improvement and consequently may change. All changes to Wynk functions and services shall be published by Wynk from time to time on the website www.wynk.ng All such published changes shall form part of this Agreement.</p>
      </article>
      <article className="mb-6">
        <h3 className="font-semibold mb-2">Force Majeure</h3>
        <p>Wynk shall not be in breach of its obligations under this Agreement or be responsible for any delay in carrying out its obligations if performance is prevented or delayed wholly or in part as a consequence of force majeure. Force majeure means any circumstance beyond the reasonable control of Wynk including but not limited to acts of war, state or national emergency, strike, rebellion, insurrection, government sanctions, actions of regulatory or supervisory authorities, accident, power failure, internet and communication link failure, fire, earthquake, flood, storm, tornadoes, hurricane, epidemic or pandemic, collapse of buildings, fire, explosion, events of force majeure declared by Wynk' partners or service providers involved in the performance of Wynk' obligations in this Agreement or any other act of God or any technical failure caused by devices, matters or materials.</p>
      </article>
      </section>
      <Footer/>
  </main>
  )
}